import React from 'react';
import { getCodeAndNameByOwnerShip } from 'utilities/format';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import { LifeCycleIndicatorTag } from 'components/vehicle-details/widgets/life-cycle';
import { emdash } from 'components/common';
import {
  VehicleTableCell,
  VehicleDetailsRow,
  VehicleRowActionButton,
} from './table-widgets';
import { carTitleConstructor } from '../../../utilities/car-title';
import {
  determineRegistrationStatus,
  VehicleRegistrationStatusTag,
} from '../../vehicle-registration-status-tag';

const getRowMenuItems = (
  reg,
  registrationAction,
  canRegister,
  exemptPlate,
  registerStatus,
  canAddVehicleToPool,
) => {
  const registrationItem =
    reg || exemptPlate
      ? {
          label: 'Edit vehicle registration',
          action: 'editRegistration',
          icon: 'edit',
        }
      : {
          label: 'Register vehicle',
          action: 'register',
          icon: 'edit',
        };

  let items = [];

  if (registerStatus === 'Unregistered') {
    items.push({
      label: 'View vehicle details',
      action: 'vehicleDetails',
      icon: 'visibility',
    });
  } else if (registerStatus === 'Incomplete' && (!reg || !exemptPlate)) {
    items.push({
      label: 'Edit vehicle registration',
      action: 'editRegistration',
      icon: 'edit',
    });
  }

  if (registrationAction && canRegister) {
    items = items.filter((item) => item.label !== registrationItem.label);
    items.push({
      ...registrationItem,
    });
  }

  if (registerStatus === 'Registered') {
    items.push({
      label: 'Download vehicle registration card',
      action: 'downloadRegistration',
      icon: 'pdf',
      iconType: 'custom',
    });
  }

  if (canAddVehicleToPool && isFeatureEnabled('motor-pools')) {
    items.push({
      icon: 'add',
      label: 'Add vehicle to pool',
      action: 'addVehicleToMotorPool',
    });
  }

  return items;
};

const formatVehicleTableRow = (
  rowItem,
  buttonAction,
  canRegister,
  i,
  canAddVehicleToPool,
) => {
  const {
    id,
    agency,
    makeColor,
    modelYear,
    modelCode,
    tag,
    tagNumber,
    primaryPoC,
    secondaryPoC,
    vehicleMake,
    vehicleModel,
    exemptPlate,
    agencyCode,
    customer,
    ownershipTypeCode,
    assetLifecycle,
    versionId,
    isCurrent,
  } = rowItem;

  const showRegistrationAction = () => {
    return id && (!tag?.id || (primaryPoC && secondaryPoC));
  };

  return {
    makeColor: makeColor?.name,
    poc: primaryPoC || secondaryPoC,
    vin: id,
    exemptPlate,
    lifecycleIndicator: assetLifecycle?.[0]?.lifeCycle?.lifecycleIndicator ? (
      <LifeCycleIndicatorTag
        value={assetLifecycle[0]?.lifeCycle?.lifecycleIndicator}
      />
    ) : (
      emdash
    ),
    id: (
      <VehicleTableCell
        key={`${id}-${i}`}
        title={carTitleConstructor(
          modelYear,
          vehicleMake,
          vehicleModel,
          modelCode,
        )}
        vin={id}
        versionId={versionId}
        isCurrent={isCurrent}
      />
    ),
    tagNumber: tagNumber || emdash,
    registered: <VehicleRegistrationStatusTag vehicle={rowItem} />,
    agency: `${getCodeAndNameByOwnerShip({
      ownershipTypeCode,
      code: agencyCode,
      name: agency?.name,
      customer,
    })}
    `,
    subRowStructure: [
      <VehicleDetailsRow
        id={id}
        makeColor={makeColor?.name}
        poc={primaryPoC || secondaryPoC}
        vin={id}
        exemptPlate={exemptPlate}
      />,
    ],
    actions: (
      <VehicleRowActionButton
        vin={id}
        onClick={buttonAction}
        menuItems={getRowMenuItems(
          tagNumber,
          showRegistrationAction(),
          canRegister,
          exemptPlate,
          determineRegistrationStatus(rowItem),
          canAddVehicleToPool,
        )}
        vehicle={rowItem}
      />
    ),
  };
};

export const formatDataForTable = (
  queryData,
  buttonAction,
  canRegister,
  canAddVehicleToPool,
) => {
  const rows = queryData?.getVehiclesByNestedFilters?.rows;

  if (rows) {
    return rows.map((row, i) => {
      const canRegisterVehicle = canRegister(row);
      return formatVehicleTableRow(
        row,
        buttonAction,
        canRegisterVehicle,
        i,
        canAddVehicleToPool,
      );
    });
  }
  return [];
};

export default {
  formatDataForTable,
};
