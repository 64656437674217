import PropTypes from 'prop-types';

export const MenuItemsType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    icon: PropTypes.string,
  }),
);

export const VehicleRowActionButtonType = {
  vin: PropTypes.string,
  onclick: PropTypes.func,
  menuItems: MenuItemsType,
};

export const PoCType = PropTypes.shape({
  fullName: PropTypes.string,
  email: PropTypes.string,
  primaryPhone: PropTypes.string,
  secondaryPhone: PropTypes.string,
});

export const vehicleTableCellType = {
  title: PropTypes.string,
  vin: PropTypes.string,
  versionId: PropTypes.string,
  isCurrent: PropTypes.bool,
};

export const DetailRowType = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export const VehicleDetailsRowType = {
  poc: PoCType,
  vin: PropTypes.string,
  makeColor: PropTypes.string,
  status: PropTypes.string,
};
