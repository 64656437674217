// eslint-disable-next-line filenames/match-exported
import LicensePlateAgencyFilterItem from '../custom-filters/license-plate-agency-filter-item';
import LicensePlateBureauFilterItem from '../custom-filters/license-plate-bureau-filter-item';
import LicensePlateOfficeFilterItem from '../custom-filters/license-plate-office-filter-item';
import LicensePlateStatusFilterItem from '../custom-filters/license-plate-status-filter-item';

const typeaheadOpts = {
  type: 'typeahead',
  placeholder: 'All',
  value: '',
};

export const licensePlateWithVehicleType = {
  topLevelSearch: {
    title: 'License plate',
    key: 'tagNumber',
    type: 'text',
    permanent: false,
    operator: 'STARTWITH',
    hideClear: false,
    position: 'top',
    label: 'Search by plate number',
    showSearchButton: true,
    minSearchLength: 3,
  },
  licensePlateFSR: {
    ariaLabel: 'Search FSR',
    expanded: true,
    key: 'fsrEmail',
    operator: 'STARTWITH',
    permanent: false,
    placeholder: 'sample@gsa.gov',
    title: 'FSR',
    type: 'typeahead',
    value: '',
  },
  licensePlateDetails: {
    title: 'Plate type',
    key: 'plate-type',
    type: 'multiselect',
    permanent: false,
    field: 'id',
    operator: 'nestedOperator',
    value: [],
    options: [
      { value: 'Motorcycle', label: 'Motorcycle' },
      { value: 'Trailer', label: 'Trailer' },
    ],
    hideClear: true,
  },
  licensePlateStatus: {
    title: 'Plate status',
    key: 'tagactivitytypecode',
    permanent: false,
    component: LicensePlateStatusFilterItem,
    operator: 'IN',
    value: [],
    hideClear: true,
  },
  licensePlateOwnership: {
    title: 'Ownership',
    key: 'ownership',
    type: 'multiselect',
    field: 'tagNumber',
    operator: 'nestedOperator',
    permanent: false,
    value: [],
    options: [
      { value: 'Agency Owned', label: 'Agency Owned' },
      { value: 'GSA Leased', label: 'GSA Leased' },
    ],
    hideClear: true,
  },
  licensePlateOrder: {
    title: 'UNICOR order',
    ariaLabel: 'Search UNICOR orders',
    key: 'orderNumber',
    field: '$unicorTag.orderNumber$',
    model: 'UnicorTag',
    permanent: false,
    operator: 'EQ',
    ...typeaheadOpts,
  },
  agency: {
    title: 'Agency',
    key: 1,
    filters: [
      {
        key: 'customerAgencyCode',
        title: 'Agency',
        component: LicensePlateAgencyFilterItem,
        permanent: false,
        operator: 'EQ',
        value: '',
      },
      {
        key: 'customerBureauCode',
        title: 'Bureau',
        component: LicensePlateBureauFilterItem,
        placeholder: '- Select bureau -',
        noResults: 'No bureaus found',
        permanent: false,
        operator: 'IN',
      },
      {
        key: 'customerSubSectionCode',
        title: 'Office',
        component: LicensePlateOfficeFilterItem,
        placeholder: '- Select office -',
        noResults: 'No offices found',
        permanent: false,
        operator: 'IN',
      },
    ],
  },
};

export default licensePlateWithVehicleType;
