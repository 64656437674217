export const consolidationMapping = [
  ['Consolidation Number', 'consolidationNumber', 'string'],
  ['Consolidation Date', 'consolidationDate', 'string'],
  ['FMC Name', 'fmcName', 'string'],
  ['FSR email', 'fsrEmail', 'string'],
  ['GSA License Plate', 'gsaLicensePlate', 'string'],
  ['VIN', 'vin', 'string'],
  ['Model Year', 'modelYear', 'number'],
  ['Make', 'make', 'string'],
  ['Model', 'model', 'string'],
  ['SIN', 'sin', 'string'],
  ['Acquisition Method', 'acquisitionMethod', 'string'],
  ['Initial Acquisition Date', 'initialAcquisitionDate', 'string'],
  ['Initial Acquisition Cost', 'initialAcquisitionCost', 'string'],
  ['Unit of measure', 'unitOfMeasure', 'string'],
  ['Odometer', 'odometer', 'number'],
  ['GVWR', 'gvwr', 'number'],
  ['Fuel Tank Capacity', 'fuelTankCapacity', 'string'],
  ['Color', 'color', 'string'],
  ['Engine Size (L)', 'engineSize', 'string'],
  ['Cylinders', 'cylinders', 'number'],
  ['Fuel Type', 'fuelType', 'string'],
  ['Tire Size', 'tireSize', 'string'],
  ['Tires in use', 'tiresInUse', 'number'],
  ['Oil Life System (OLS)', 'ols', 'string'],
  ['Transmission Type', 'transmissionType', 'string'],
  ['Warranty Months', 'warrantyMonths', 'number'],
  ['Warranty Mileage', 'warrantyMileage', 'number'],
  ['RPNN', 'rpnn', 'string'],
  ['Agency Prov Book Value?', 'agencyProvBookValue', 'string'],
  ['Book Value', 'bookValue', 'string'],
  ['Customer Number', 'customerNumber', 'string'],
  ['Garage Country', 'garageCountry', 'string'],
  ['Garage Address 1', 'garageAddress1', 'string'],
  ['Garage Address 2', 'garageAddress2', 'string'],
  ['Garage Address 3', 'garageAddress3', 'string'],
  ['Garage City', 'garageCity', 'string'],
  ['Garage State', 'garageState', 'string'],
  ['Garage Zip Code', 'garageZip', 'string'],
];
