/* eslint-disable react/prop-types */
import moment from 'moment';
import {
  Button,
  RequiredFieldIndicator,
  Modal,
  Alert,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import FormattedNumberControl from 'utilities/form-generator-controls/formatted-number-control';
import { isSiteAdmin } from 'utilities/authorization';
import { GET_DISTINCT_STANDARD_ITEMS } from 'services/data-layer';

import { FG_DEFAULT_OPTION_VAL, purchaseTypeOptions } from 'utilities/consts';

import { getOptionsWithDefault } from 'utilities/form-generator-controls/helper';
import { emdash } from 'components/common';

export const datePickerValidate = (vehicle) => (val, ctx) => {
  const isDisposalLessThanAcquisitionDate =
    moment(vehicle?.disposalDate).diff(moment(val)) <= 0;
  const isAcquisitionDateMoreThanYesterday =
    moment(val).diff(moment(new Date())) > 0;
  if (isDisposalLessThanAcquisitionDate || isAcquisitionDateMoreThanYesterday) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        "Acquisition date must be on or before today's date and less than disposal date (when provided)",
    });
  }
};

const validateDate = (dateString) => {
  if (dateString) {
    const dateInput = new Date(dateString);
    return dateInput <= new Date();
  }
  return true;
};

export default function GFPurchaseInfoEdit({
  onClose,
  vehicle,
  onSave,
  isGFModalOpen,
  errorMessage,
}) {
  const purchaseTypeSelectOptions = purchaseTypeOptions.map((option) => ({
    value: option.code,
    label: option.description,
  }));
  const ability = useAppAbility();
  const isSiteAdminUser = isSiteAdmin(ability);
  const [sins, setSins] = useState([]);
  const [standardItemNumber, setStandardItemNumber] = useState();
  const placeholderOption = {
    value: FG_DEFAULT_OPTION_VAL,
    label: '-Select-',
  };

  const [getStandardItems, { data: getDistinctStandardItemsResponse }] =
    useLazyQuery(GET_DISTINCT_STANDARD_ITEMS, {
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (getDistinctStandardItemsResponse) {
      const formattedOptions =
        getDistinctStandardItemsResponse.getDistinctStandardItems.length > 0 &&
        getDistinctStandardItemsResponse.getDistinctStandardItems.map(
          (standardItem) => ({
            value: standardItem.standardItemNumber,
            label: `${standardItem?.standardItemNumber} ${standardItem?.title}`,
          }),
        );
      setSins(
        formattedOptions
          ? [placeholderOption, ...formattedOptions]
          : [placeholderOption],
      );
    }
  }, [getDistinctStandardItemsResponse]);

  useEffect(() => {
    getStandardItems({ variables: { year: vehicle?.year } });
  }, [vehicle]);

  const alertData = {
    showAlert: false,
    type: '',
    message: '',
  };
  const [alertContent, setAlertContent] = useState(alertData);

  useEffect(() => {
    if (errorMessage && errorMessage.error) {
      setAlertContent({
        showAlert: true,
        type: 'error',
        message: errorMessage.error,
      });
    }
  }, [errorMessage]);

  const close = () => {
    setStandardItemNumber(undefined);
    setAlertContent(alertData);
    onClose();
  };
  const schema = z.object({
    purchaseType: z
      .string()
      .refine((data) => data !== FG_DEFAULT_OPTION_VAL, 'This is required'),
    acquisitionCost: z.coerce
      .number()
      .refine((data) => data && data > 0, 'This is required'),
    deliveredMiles: z.string().refine((data) => data, 'This is required'),
    originalAcquisitionDate: z.coerce
      .date({
        invalid_type_error: 'This is required',
      })
      .superRefine(datePickerValidate(vehicle)),
    consolidationNumber: z.string().nullish(),
    consolidationDate: z
      .string()
      .nullish()
      .refine(validateDate, { message: 'Please enter a valid date' }),
    purchaseOrderNumber: z.string().nullish(),
    requisitionNumber: z.string().nullish(),
    standardItemNumber: z.string().nullish(),
  });

  const data = {
    purchaseType: vehicle?.assetAcquisition?.purchaseType
      ? vehicle?.assetAcquisition?.purchaseType.toString()
      : FG_DEFAULT_OPTION_VAL,
    deliveredMiles: vehicle?.assetAcquisition?.deliveredMiles
      ? vehicle?.assetAcquisition?.deliveredMiles.toString()
      : '',
    originalAcquisitionDate:
      vehicle?.assetAcquisition?.originalAcquisitionDate || emdash,
    acquisitionCost: vehicle?.assetAcquisition?.acquisitionCost
      ? vehicle?.assetAcquisition?.acquisitionCost.toString()
      : '',
    requisitionNumber: vehicle?.assetAcquisition?.requisitionNumber || emdash,
    consolidationNumber:
      vehicle?.assetAcquisition?.consolidationNumber || emdash,
    consolidationDate: vehicle?.assetAcquisition?.consolidationDate,
    purchaseOrderNumber: vehicle?.assetAcquisition?.purchaseOrderNumber,
    standardItemNumber:
      standardItemNumber ||
      vehicle.standardItem?.standardItemNumber ||
      FG_DEFAULT_OPTION_VAL,
  };

  const content = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'purchaseType',
            label: 'Purchase type',
            type: 'select',
            options: getOptionsWithDefault(purchaseTypeSelectOptions),
            required: true,
          },
          {
            id: 'acquisitionCost',
            label: 'Acquisition cost',
            type: 'currency',
            required: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'deliveredMiles',
            label: 'Delivered miles',
            type: 'format-number',
            element: {
              control: { attachments: { after: ['miles'] } },
            },
            required: true,
          },
          {
            id: 'originalAcquisitionDate',
            label: 'Delivered date',
            type: 'datepicker',
            required: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'consolidationNumber',
            label: 'Consolidation Number',
            type: 'text',
            disabled: !isSiteAdminUser,
          },
          {
            id: 'consolidationDate',
            label: 'Consolidation date',
            type: 'datepicker',
            required: false,
            disabled: !isSiteAdminUser,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '16px',
        fields: [
          {
            id: 'purchaseOrderNumber',
            label: 'Order Number',
            type: 'text',
            disabled: !isSiteAdminUser,
          },
          {
            id: 'requisitionNumber',
            label: 'Requisition Number',
            type: 'text',
            disabled: !isSiteAdminUser,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '16px',
        fields: [
          {
            id: 'standardItemNumber',
            label: 'SIN',
            type: 'select',
            options: sins,
            disabled: !isSiteAdminUser,
          },
          { type: 'spacer' },
        ],
      },
    ],
  };

  return (
    isGFModalOpen && (
      <div className="afp-modal-wrapper">
        <div className="afp-modal-overlay">
          <Modal
            variant="large"
            title={<h2>Edit purchase information</h2>}
            onClose={close}
            showAlert={alertContent.showAlert}
            alert={
              <Alert type={alertContent.type} slim>
                {alertContent.message}
              </Alert>
            }
            actions={
              <>
                <Button variant="unstyled" onClick={close} label="Close" />
                <Button
                  className="margin-left-2"
                  type="submit"
                  form="gf-purchase-edit-form"
                  label="Save and close"
                />
              </>
            }
          >
            <p>
              Edit purchase information for for VIN{' '}
              <span className="text-bold">{vehicle.id}</span> in the form below.
            </p>
            <p>
              Required fields are marked with an asterisk (
              <RequiredFieldIndicator />
              ).
            </p>
            <FormGenerator
              id="gf-purchase-edit-form"
              schema={schema}
              content={content}
              defaultValues={data}
              controls={{ 'format-number': FormattedNumberControl }}
              onSubmit={onSave}
              onChange={[
                ['standardItemNumber'],
                (methods) => {
                  if (
                    methods.watch('standardItemNumber') !==
                    data.standardItemNumber
                  ) {
                    if (
                      methods.watch('standardItemNumber') !==
                      FG_DEFAULT_OPTION_VAL
                    ) {
                      setStandardItemNumber(
                        methods.watch('standardItemNumber'),
                      );
                    }
                  }
                },
              ]}
            />
          </Modal>
        </div>
      </div>
    )
  );
}
