import React from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import StatusFilterItem from './status-filter-item';

const { FilterPanelFilterShape } = FilterPanel;

const LicensePlateStatusFilterItem = ({ filter }) => {
  return <StatusFilterItem filter={filter} statusPath="tagactivitytypecode" />;
};

LicensePlateStatusFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default LicensePlateStatusFilterItem;
