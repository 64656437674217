import { gql } from '@apollo/client';

export const AttachmentFields = gql`
  fragment AttachmentFields on Attachment {
    id
    name
    description
    contentURL
    type
    size
    lastModified
    createdAt
    updatedAt
    createdByUser
    user {
      fullName
    }
  }
`;

export const VehicleFields = gql`
  fragment VehicleFields on Vehicle {
    id
    tagNumber
    comments {
      count
    }
    attachments {
      count
    }
    fleetVendor {
      fleetVendorNumber
      vendorName
    }
    vehicleMake {
      makeCode
      makeName
    }
    vehicleModel {
      modelCode
      makeCode
      modelDescription: modelName
    }
    customer {
      customerId
      firstName
    }
  }
`;

export const MotorPoolVehicleFields = gql`
  fragment MotorPoolVehicleFields on Vehicle {
    id: vin
    motorPoolVehicle {
      id
      motorPoolId
      motorPool {
        poolName
      }
    }
  }
`;

export const AssetTechnicalFields = gql`
  fragment AssetTechnicalFields on Vehicle {
    assetTechnical {
      gvwr
      tireSize
      wheelBase
      cabToAxle
      bumperToBack
      payload
      pto
      tireLoadRange
      tireSpeedRating
      tireQuantity
      pmScheduleId
      pmSchedule {
        pmScheduleId
        pmScheduleNumber
        pmTitle
        pmMilesInterval
        pmKilometersInterval
        pmMonthsInterval
        pmType
        pmAdditionalNotes
        pmRequiredService
      }
      assetTechnicalIce {
        assetTechnicalId
        rangeInMiles
        transmission
        cylinders
        engineDisplacement
        horsePower
        tankSize
        mpgCity
        mpgHighway
        mpgCombined
        flexFuelMpgCity
        flexFuelMpgHighway
        flexFuelMpgCombined
        gramPerMile
        flexFuelGramPerMile
      }
    }
  }
`;

export const VehiclePOCFields = gql`
  fragment VehiclePOCFields on Vehicle {
    primaryPoC {
      contactId
      firstName
      lastName
      fullName
      email
      primaryAddress
      secondaryAddress
      tertiaryAddress
      city
      postalCode
      stateCode
      countryCode
      primaryPhone
      primaryPhoneExt
    }
    secondaryPoC {
      contactId
      firstName
      lastName
      fullName
      email
      primaryAddress
      secondaryAddress
      tertiaryAddress
      city
      postalCode
      stateCode
      countryCode
      primaryPhone
      primaryPhoneExt
    }
  }
`;

export const VehicleTagFields = gql`
  fragment VehicleTagFields on Vehicle {
    tag {
      id
      aOrB
      expirationDate
      activity {
        performedBy
        performedByUser {
          fullName
          email
        }
        createdAt
      }
      customerPointOfContactEmailAddress
      customerPointOfContactAddress1
      customerPointOfContactAddress2
      customerPointOfContactAddress3
      customerPointOfContactAddress4
      customerPointOfContactCity
      customerPointOfContactName
      customerPointOfContactPhone
      customerPointOfContactState
      customerPointOfContactZip
      tagActivityTypeCode
    }
    tagNew {
      expirationDate
    }
  }
`;

export const VehicleCustomerFields = gql`
  fragment VehicleCustomerFields on Vehicle {
    customer {
      agency {
        name
        shortName
      }
      bureau {
        name
        agencyIndicatorCode
      }
      customerId
      boac
      customerAgencyCode
      customerBureauCode
      officeName
      customerPhysicalOffice {
        officeCode
        officeName
      }
      mileageReportingReminder
      maintenanceReminder
      fsrUserEmail
      legacyCustomerNumber
      zoneId
      accountName
    }
  }
`;

export const VehicleOdometerFields = gql`
  fragment VehicleOdometerFields on Vehicle {
    newOdometerReading {
      assetId
      mileageDate
      odometer
      createdAt
    }
  }
`;

export const VehicleLocationFields = gql`
  fragment VehicleLocationFields on Vehicle {
    assetLocation {
      address1
      address2
      address3
      stateCode
      city
      postalCode
      countryCode
      locationName
    }
  }
`;

export const VehicleCustodianFields = gql`
  fragment VehicleCustodianFields on Vehicle {
    assetCustodian {
      entityId
      entityType
    }
  }
`;

export const VehicleFieldsFull = gql`
  fragment VehicleFieldsFull on Vehicle {
    id: vin
    uuid: id
    versionId
    isCurrent
    tagNumber
    lifecycleIndicatorId
    assetUsage {
      assetId
      vamExempt
      executiveFleet
      agencyUse1
      agencyUse2
      agencyUse3
      agencyUse4
    }
    assetLifecycle {
      lifecycleIndicatorBeginDate
      lifeCycle {
        lifecycleIndicatorId
        lifecycleIndicatorStatus
        lifecycleIndicator
      }
    }
    lifecycleIndicator {
      lifecycleIndicatorId
      lifecycleIndicator
    }
    assetPreventativeMaintenanceSummary {
      pmStatus
      nextPmMileage
      nextPmDate
      currentPmMileage
      currentPmDate
    }
    assetMileageReportingStatus {
      mileageReportingStatus
      mileageReportingYear
      mileageReportingMonth
      currentMileage
      updatedAt
    }
    exemptPlate
    itemType
    customerId
    modelCode
    makeCode
    modelYear
    pre1981
    internationalVin
    unverifiedVin
    nhtsaVerified
    makeColorName
    fuelCode
    fuel {
      description
      fastDescription
    }
    fastReportable
    fmcId
    vehicleTrim
    vehicleSeries
    unverifiedVin
    vehicleLeaseRateCode
    itemInventoryStatusCode
    itemInventoryStatusDateTime
    inventoryStatusMileage
    inventoryStatus {
      id
      statusCategory
      description
    }
    fsrUserEmail
    ownershipTypeCode
    agencyCode
    bureauCode
    officeCode
    isRegistered
    createdBy
    createdAt
    updatedBy
    updatedAt
    registeredAt
    registeredByUser {
      fullName
    }
    vehicleMake {
      makeCode
      makeName
    }
    vehicleModel {
      modelCode
      makeCode
      modelTypeCode: modelName
      modelDescription: modelName
    }
    agency {
      name
    }
    bureau {
      id
      name
      agencyCode
    }
    office {
      officeCode
      officeName
    }
    standardItem {
      standardItemNumber
    }
    makeColor {
      name
      makeColorCode
    }
    comments {
      count
    }
    recallCounts
    overduePmsCount
    supportingDocument {
      metadataId
    }
    assetAccessory {
      accessoryCode
    }
    assetReplacement {
      wasReplacementOrdered
      isReplacementEligible
      eligibleReplacementDate
    }
    assetDisposal {
      actualDisposalDate
    }
    ald {
      stateTag
      stateTagExpirationDate
      fundCode
      account1
      account2
      afiNumber
    }
    assetAcquisition {
      keyCodeIgnition
      keyCodeOther
      purchaseType
      purchaseOrderNumber
      originalAcquisitionDate
      consolidationNumber
      consolidationDate
      requisitionNumber
      acquisitionCost
      deliveredMiles
    }
    fleetManagementCenter {
      name
      zoneId
    }
    ...VehicleTagFields
    ...VehicleCustomerFields
    ...VehiclePOCFields
    ...AssetTechnicalFields
    ...VehicleOdometerFields
    ...VehicleLocationFields
    ...VehicleCustodianFields
  }
  ${VehicleTagFields}
  ${VehicleCustomerFields}
  ${VehiclePOCFields}
  ${AssetTechnicalFields}
  ${VehicleOdometerFields}
  ${VehicleLocationFields}
  ${VehicleCustodianFields}
`;

export const VehicleCommentFields = gql`
  fragment VehicleCommentFields on VehicleComment {
    id
    comment
    createdBy
    author {
      fullName
    }
    createdAt
    deletedAt
  }
`;

export const TagCommentFields = gql`
  fragment TagCommentFields on TagComment {
    id
    comment
    createdBy
    author {
      fullName
    }
    createdAt
    deletedAt
  }
`;

export const VehicleRegistrationDetails = gql`
  fragment VehicleRegistrationDetails on Vehicle {
    id
    customerId
    customer {
      customerId
      agency {
        name
      }
      bureau {
        name
      }
      office {
        officeName
      }
    }
    modelYear
    vehicleMake {
      makeName
      makeCode
    }
    vehicleModel {
      modelDescription: modelName
    }
    itemType
    tagNumber
    exemptPlate
    ...VehicleTagFields
    unverifiedVin
    pre1981
    internationalVin
    nhtsaVerified
    fuelCode
    fastReportable
    makeCode
    modelCode
    makeColorName
    itemInventoryStatusCode
    inventoryStatus {
      id
      description
    }
    makeColor {
      makeColorCode
      name
    }
    fuel {
      description
    }
    ald {
      statePlate: stateTag
    }
    ...VehiclePOCFields
    agency {
      name
    }
    bureau {
      name
    }
    office {
      officeName
      officeCode
    }
    ownershipTypeCode
    agencyCode
    bureauCode
    supportingDocument {
      metadataId
    }
    createdByUser {
      fullName
      email
    }
    updatedByUser {
      fullName
      email
    }
    createdAt
    updatedAt
    registeredAt
    registeredByUser {
      fullName
    }
    assetContactAssociationPrimary {
      pointOfContactPrimary {
        contactId
        pocFirstName
        pocLastName
        pocFullName
        pocEmailAddress
        pocAddress1
        pocAddress2
        pocAddress3
        pocCity
        pocPostalCode
        pocStateCode
        pocPhone1CountryCode
        pocPhone1
        pocPhone1Ext
      }
    }
    assetContactAssociationSecondary {
      pointOfContactSecondary {
        contactId
        pocFirstName
        pocLastName
        pocFullName
        pocEmailAddress
        pocAddress1
        pocAddress2
        pocAddress3
        pocCity
        pocPostalCode
        pocStateCode
        pocPhone1CountryCode
        pocPhone1
        pocPhone1Ext
      }
    }
  }
  # primaryPoC {
  #   contactId
  #   firstName
  #   lastName
  #   fullName
  #   email
  #   primaryAddress
  #   secondaryAddress
  #   tertiaryAddress
  #   city
  #   postalCode
  #   stateCode
  #   countryCode
  #   primaryPhone
  #   primaryPhoneExt
  # }
  # secondaryPoC {
  #   contactId
  #   firstName
  #   lastName
  #   fullName
  #   email
  #   primaryAddress
  #   secondaryAddress
  #   tertiaryAddress
  #   city
  #   postalCode
  #   stateCode
  #   countryCode
  #   primaryPhone
  #   primaryPhoneExt
  # }
  ${VehicleTagFields}
`;

export const VehicleInventoryRows = gql`
  fragment VehicleInventoryRows on Vehicle {
    id: vin
    uuid: id
    versionId
    isCurrent
    agencyCode
    agency {
      name
    }
    bureauCode
    bureau {
      name
    }
    customer {
      customerId
      agency {
        name
      }
      bureau {
        name
      }
      customerAgencyCode
      customerBureauCode
    }
    isRegistered
    updatedAt
    modelCode
    modelYear
    fastReportable
    makeCode
    vehicleMake {
      makeName
      makeCode
    }
    modelYear
    vehicleModel {
      modelDescription: modelName
    }
    assetLifecycle {
      lifeCycle {
        lifecycleIndicator
        lifecycleIndicatorStatus
      }
    }
    fuel {
      description
    }
    itemInventoryStatusCode
    ownershipTypeCode
    tagNumber
    exemptPlate
    tag {
      id
      aOrB
      expirationDate
    }
    makeColor {
      name
    }
    assetContactAssociationPrimary {
      pointOfContactPrimary {
        contactId
        pocFirstName
        pocLastName
        pocFullName
        pocEmailAddress
        pocAddress1
        pocAddress2
        pocAddress3
        pocCity
        pocPostalCode
        pocStateCode
        pocPhone1CountryCode
        pocPhone1
        pocPhone1Ext
      }
    }
    assetContactAssociationSecondary {
      pointOfContactSecondary {
        contactId
        pocFirstName
        pocLastName
        pocFullName
        pocEmailAddress
        pocAddress1
        pocAddress2
        pocAddress3
        pocCity
        pocPostalCode
        pocStateCode
        pocPhone1CountryCode
        pocPhone1
        pocPhone1Ext
      }
    }
    # primaryPoC {
    #   contactId
    #   firstName
    #   lastName
    #   fullName
    #   email
    #   primaryAddress
    #   secondaryAddress
    #   tertiaryAddress
    #   city
    #   postalCode
    #   stateCode
    #   countryCode
    #   primaryPhone
    #   primaryPhoneExt
    # }
    # secondaryPoC {
    #   contactId
    #   firstName
    #   lastName
    #   fullName
    #   email
    #   primaryAddress
    #   secondaryAddress
    #   tertiaryAddress
    #   city
    #   postalCode
    #   stateCode
    #   countryCode
    #   primaryPhone
    #   primaryPhoneExt
    # }
  }
`;
