import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import useUser from 'utilities/use-user';

const { FilterPanelField, FilterPanelFilterShape, useFilterPanel } =
  FilterPanel;

const defaultOptions = [
  { value: 'ATTD', label: 'Attached' },
  { value: 'SHIP', label: 'Shipped' },
  { value: 'RECV', label: 'Received' },
  { value: 'MISS', label: 'Missing' },
  { value: 'RETN', label: 'Pending destruction' },
  { value: 'DESV', label: 'Destroyed' },
  { value: 'UNAT', label: 'Unattached' },
];

const StatusFilterItem = ({ filter, statusPath }) => {
  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();
  const { isGsaEmployee } = useUser();
  const internalGsa = isGsaEmployee();

  const [options, setOptions] = useState([]);
  const [status, setStatus] = useState('');

  useEffect(() => {
    const statusFilter = getFilterFromState(statusPath) || {};
    setStatus(statusFilter?.value || '');
  }, [mergedFilters]);

  useEffect(() => {
    clearOneFilter(filter);
    setOptions([]);
    if (internalGsa) {
      setOptions([...defaultOptions, { value: 'FLOR', label: 'Ordered' }]);
    } else {
      setOptions(defaultOptions);
    }
  }, [internalGsa]);

  return (
    <div className="margin-top-3">
      <FilterPanelField
        key={filter.key}
        filter={{
          ...filter,
          type: 'multiselect',
          options,
          value: status || [],
        }}
      />
    </div>
  );
};

StatusFilterItem.defaultProps = {
  statusPath: '',
};

StatusFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
  statusPath: PropTypes.string,
};

export default StatusFilterItem;
