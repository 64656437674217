/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
import { useMutation } from '@apollo/client';
import { EXPORT_REPORT } from 'services/data-layer';
import exportMessage from 'utilities/export-message';
import { REPORT_DELIVERY_METHOD, REPORT_TYPE } from 'utilities/consts';
import { useRecallPage, useRecallFilter } from './providers';

const useDataController = () => {
  const { setBannerMsg, recallCount } = useRecallPage();
  const { filters } = useRecallFilter();
  const [exportData] = useMutation(EXPORT_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setBannerMsg(exportMessage.exportSuccessMessage);
    },
    onError: () => {
      setBannerMsg(exportMessage.exportMsgError);
    },
  });

  const onExport = () => {
    setBannerMsg(null);
    if (recallCount === 0) {
      setBannerMsg(exportMessage.exportMsgNoData('recalls'));
      return;
    }
    if (recallCount > 950000) {
      setBannerMsg(exportMessage.exportMsgTooMany);
    } else {
      let filterData;
      if (filters?.conditions?.[0]?.legacyCustomerNumber) {
        filterData = filters?.conditions[0]?.legacyCustomerNumber;
      } else {
        filterData = filters;
      }
      exportData({
        variables: {
          request: {
            reportType: REPORT_TYPE.RECALL_DATA_EXPORT,
            deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
            reportCriteria: {
              filters: filterData,
            },
          },
        },
      });
    }
  };

  return {
    onExport,
  };
};

export default useDataController;
