import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  Breadcrumbs,
  Spinner,
  Alert,
  SideNav,
} from '@gsa/afp-component-library';
import {
  useTitle,
  getIsOrgOptInLazyQuery,
  InternalError,
  useAppAbility,
  VehicleSearch,
} from '@gsa/afp-shared-ui-utils';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import Header from './widgets/header';
import VehicleActions from './widgets/vehicle-actions';
import {
  IncompleteWarning,
  NewLicensePlateAlert,
  LicensePlateRemovedAlert,
  getNewLicensePlateMessage,
} from './widgets/vehicle-alerts';
import ServerErrorMessage from '../error-handlers/server-error-message';
import VehicleContextProvider, {
  vehicleContext,
} from './vehicle-context-provider';
import {
  VEHICLE_DETAIL,
  SWAP_TAG,
  GET_AL_CATEGORY,
} from '../../services/data-layer';
import { vehicleRegistrationIsIncomplete } from '../vehicle-registration-status-tag';
import {
  getVehicleDetailsSideMap,
  VEHICLE_DETAILS_SIDENAV_MAPPING as SIDENAV,
  OPT_IN_SIDENAV_ITEMS,
} from './sidenav-widgets/sidenav-mapping';
import { generateSideNavItems } from './helpers/utils';
import { useFeature } from '../../utilities/features';
import {
  isRecallEnabled,
  isMileageEnabled,
  isCDDEnabled,
  isModificationEnabled,
  isPmEnabled,
  isValuationEnabled,
  isBillingHistoryEnabled,
} from './helpers/feature-helper';
import { useAlertMessages } from '../../hooks/use-alert-messages';
import useUser from '../../utilities/use-user';

const getCurrentSegment = (currentPath) =>
  currentPath.substring(currentPath.lastIndexOf('/') + 1);

const VehicleDetails = () => {
  const location = useLocation();
  const ability = useAppAbility();

  const [vehicle, setVehicle] = useState(null);
  const { isGsaEmployee, isRole } = useUser();
  let { vin } = useParams();
  const history = useHistory();
  const queryParams = new URLSearchParams(history?.location?.search);
  const vehicleVersionId = queryParams.get('version');

  if (vin) {
    vin = decodeURIComponent(vin);
  }
  useTitle(`VIN ${vin}`);
  const trail = [
    <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
    <Link to="/vehicles">Vehicle Inventory</Link>,
  ];

  const [hasSystemError, showSystemError] = useState(false); // standard system error
  const [pageMsg, setPageMsg] = useState(null); // page-level notification
  const [tabMsg, setTabMsg] = useState(null); // tab-level notification
  const [sectionMsg, setSectionMsg] = useState(null); // section-level notification
  const { setAlertMessages, AlertsComponent } = useAlertMessages();

  const [tagRemoved, setTagRemoved] = useState(null); // removed tag info for noticiation

  const [isOptIn, setIsOptIn] = useState(undefined);
  const [showRecalls, setShowRecalls] = useState(false);
  const [isMileageEnabledState, setIsMileageEnabledState] = useState(false);
  const [showCDD, setShowCDD] = useState(false);
  const [showModification, setShowModification] = useState(false);
  const [showPM, setShowPM] = useState(false);
  const [showValuation, setshowValuation] = useState(false);
  const [showBillingHistory, setShowBillingHistory] = useState(false);

  const features = useFeature();

  const withActiveFeatures = (item) => {
    const hasFeature = SIDENAV[item]?.featureName
      ? features[SIDENAV[item].featureName]
      : true;

    switch (item) {
      case 'recalls':
        return (
          hasFeature &&
          (vehicle?.ownershipTypeCode === 'GF' || isOptIn) &&
          showRecalls
        );
      case 'mileage':
        return (
          hasFeature &&
          (vehicle?.ownershipTypeCode === 'GF' || isOptIn) &&
          isMileageEnabledState
        );
      case 'repair':
        return (
          hasFeature &&
          ((vehicle?.ownershipTypeCode === 'GF' && isGsaEmployee()) ||
            isOptIn) &&
          isMileageEnabledState
        );
      case 'expenses':
      case 'equipment':
        return hasFeature && isOptIn && isMileageEnabledState;
      case 'valuations':
        return hasFeature && (showValuation || isOptIn);
      case 'billingHistory':
        return showBillingHistory;
      case 'specifications':
        return hasFeature && (vehicle?.ownershipTypeCode === 'GF' || isOptIn);
      case 'cdd':
      case 'cddEdit':
        return (
          hasFeature &&
          (vehicle?.ownershipTypeCode === 'GF' || isOptIn) &&
          showCDD
        );
      case 'modification':
        return showModification && isFeatureEnabled('vehicle-modification');
      case 'pm':
        return showPM && isFeatureEnabled('pm-express');
      case 'leasingRates':
        return (
          hasFeature &&
          vehicle?.ownershipTypeCode === 'GF' &&
          isFeatureEnabled('gf-rates')
        );
      case 'telematics':
        return (
          vehicle?.ownershipTypeCode === 'GF' && isFeatureEnabled('telematics')
        );
      case 'comments':
        return (
          vehicle?.ownershipTypeCode === 'AO' ||
          (vehicle?.ownershipTypeCode === 'GF' && isGsaEmployee())
        );
      case 'activities':
        /**
         * fleet base role check is needed explicitly
         * because finance users are converted to customer type and they also have a base role
         */
        return (
          vehicle?.ownershipTypeCode === 'GF' &&
          (isGsaEmployee() || isRole('FleetBaseRole')) &&
          isFeatureEnabled('vehicle-activities')
        );
      default:
        return false;
    }
  };
  const getOpenRecallCount = () =>
    vehicle?.recallCounts?.openCount > 0
      ? vehicle.recallCounts.openCount
      : null;

  const warningIcon = {
    name: 'error',
    colorToken: 'text-secondary-dark',
  };
  const getPmWarningIcon = () =>
    vehicle?.assetPreventativeMaintenanceSummary?.pmStatus === 'OVERDUE'
      ? warningIcon
      : null;
  const getSideNavMap = () =>
    getVehicleDetailsSideMap(
      withActiveFeatures,
      getOpenRecallCount,
      getPmWarningIcon,
    );

  const [activeItem, _setActiveItem] = useState(
    getCurrentSegment(location.pathname),
  );
  const [sideNavItems, setSideNavItems] = useState(getSideNavMap());
  const setActiveItem = (item) => {
    if (sideNavItems[item]) {
      _setActiveItem(item);
    }
  };

  function updateSideNav(items) {
    if (isOptIn === undefined) return;

    if (
      OPT_IN_SIDENAV_ITEMS.includes(activeItem) &&
      !withActiveFeatures(activeItem)
    ) {
      history.replace('/not-opted');
      return;
    }

    const newSideNavItems = { ...items };
    Object.keys(newSideNavItems).forEach((item) => {
      if (newSideNavItems[item]) {
        newSideNavItems[item].active = item === activeItem;
      }
    });
    setSideNavItems(newSideNavItems);
  }

  // update tab menu
  useEffect(() => {
    updateSideNav(getSideNavMap());
  }, [isOptIn, activeItem, vehicle]);

  // reset tab-level and section-level notifications when switching tabs
  useEffect(() => {
    setTabMsg(null);
    setSectionMsg(null);
  }, [activeItem]);

  const {
    data: lookupOptionData,
    loading: loadingLookupOptionData,
    error: vehicleGettingAccessories,
  } = useQuery(GET_AL_CATEGORY, {
    variables: { lookupCategories: ['ACCESSORY'] },
    fetchPolicy: 'no-cache',
  });

  const {
    data,
    refetch,
    loading: loadingVehicle,
    error: vehicleError,
  } = useQuery(VEHICLE_DETAIL, {
    variables: {
      id: vin,
      ...(vehicleVersionId ? { versionId: vehicleVersionId } : {}),
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    //  errorPolicy: 'all'  ---- Both data and error.graphQLErrors are populated, enabling you to render both partial results and error information.
    // we need implement this error Policy for page to still show the data when we have the system error because the request from AFP43154.
    // it may create some unexpected defects.  the data fetching  and the code logic of this page is not implement properly may need some refactor in the future.
    errorPolicy: 'all',
  });

  const [execIsOrgOptIn] = getIsOrgOptInLazyQuery({
    onCompleted: ({ isOrgOptedIn }) => {
      setIsOptIn(isOrgOptedIn);
    },
    onError: () => {
      setIsOptIn(false);
    },
  });

  useEffect(() => {
    if (data && data.getVehicle) {
      setVehicle({
        ...data.getVehicle,
        tasks: data.getTasks,
        attachments: data.getAttachments,
      });
      const {
        agencyCode,
        bureauCode,
        ownershipTypeCode,
        itemInventoryStatusCode,
      } = data.getVehicle;

      const recallEnabled = isRecallEnabled(
        agencyCode,
        ownershipTypeCode,
        itemInventoryStatusCode,
      );
      const mileageEnabled = isMileageEnabled(agencyCode, ownershipTypeCode);
      const cddEnabled = isCDDEnabled(
        agencyCode,
        ownershipTypeCode,
        itemInventoryStatusCode,
      );
      if (
        recallEnabled ||
        mileageEnabled ||
        (cddEnabled && ownershipTypeCode === 'AO')
      )
        execIsOrgOptIn({ variables: { agencyCode, bureauCode } });
      else setIsOptIn(false);
      setShowRecalls(recallEnabled);
      setIsMileageEnabledState(mileageEnabled);
      setShowCDD(cddEnabled);
      setShowModification(isModificationEnabled(ownershipTypeCode, ability));
      setShowPM(isPmEnabled(ability, ownershipTypeCode));
      setshowValuation(isValuationEnabled(ownershipTypeCode, ability));
      setShowBillingHistory(
        isBillingHistoryEnabled(ability, ownershipTypeCode),
      );
    }
  }, [data]);

  useEffect(() => {
    setSideNavItems(getSideNavMap());
  }, [features]);

  useEffect(() => {
    const currentTab = getCurrentSegment(location.pathname);
    setActiveItem(currentTab);
  }, [location]);

  const [swapTag, { loading: swapping }] = useMutation(SWAP_TAG, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      setPageMsg({
        type: 'error',
        message: `An unexpected error has occurred: ${error.message}`,
      });
    },
    onCompleted: () => {
      setTagRemoved({
        tagNumber: vehicle.tagNumber,
        expirationDate: vehicle.tag?.expirationDate,
      });
      setSectionMsg({
        type: 'success',
        message: getNewLicensePlateMessage(),
      });
      refetch(); // refresh vehicle data
    },
  });

  if (loadingVehicle) {
    return <Spinner className="padding-y-9" />;
  }
  // ignore the non-nullable field system error AFP43154
  // the data team made change on the database and this is the temporary solution and the only option for us to solve thi problem.
  //  we may need to change the non-nullable to nullable to solve the root issue from backend.
  if (
    vehicleError &&
    !vehicleError?.message.includes('Cannot return null for non-nullable field')
  ) {
    return <ServerErrorMessage graphQLErrors={vehicleError} />;
  }

  if (!loadingVehicle && !vehicle) {
    return (
      <Alert type="error" slim className="margin-top-3">
        No data for provided VIN number
      </Alert>
    );
  }

  if (!sideNavItems || isOptIn === undefined) {
    return <Spinner className="padding-y-9" />;
  }

  const SideNavComponent = sideNavItems[activeItem]?.component;

  const showAlert = (msg, setMsg) => {
    if (!msg?.type || !msg?.message) return null;
    return (
      <Alert
        type={msg.type}
        focused={msg.focused || true}
        heading={msg.heading}
        slim={!!msg.heading}
        onClose={!msg.hideClose && setMsg ? () => setMsg(null) : undefined}
        className="margin-bottom-2 padding-bottom-2"
      >
        {msg.message}
      </Alert>
    );
  };

  return (
    <VehicleContextProvider
      accessories={lookupOptionData?.getALByCategories?.[0]?.options}
      loadingAccessories={loadingLookupOptionData}
      vehicleGettingAccessories={vehicleGettingAccessories}
      vehicle={vehicle}
      setVehicle={setVehicle}
      refetchVehicle={refetch}
      showSystemError={() => {
        showSystemError(true);
        setPageMsg(null);
      }}
      setPageMsg={(msg) => {
        setPageMsg(msg);
        showSystemError(false);
      }}
      setTabMsg={setTabMsg}
      setSectionMsg={setSectionMsg}
      setAlertMessages={setAlertMessages}
      showAlert={showAlert}
    >
      <Breadcrumbs trail={trail} current={`VIN ${vin}`} />

      {hasSystemError && <InternalError />}
      {showAlert(pageMsg, setPageMsg)}

      {tagRemoved ? (
        <LicensePlateRemovedAlert
          ownershipTypeCode={vehicle?.ownershipTypeCode}
          tagRemoved={tagRemoved}
        />
      ) : (
        <NewLicensePlateAlert
          vehicle={vehicle}
          swapTag={swapTag}
          swapping={swapping}
        />
      )}
      {vehicle && vehicleRegistrationIsIncomplete(vehicle) && (
        <IncompleteWarning ownership={vehicle.ownershipTypeCode} />
      )}

      <div className="grid-row grid-gap flex-justify">
        <div className="tablet:grid-col">
          <Header vin={vin} />
        </div>
        <div className="margin-top-3 tablet:grid-col-5">
          <VehicleSearch />
          <div className="float-right margin-top-3 flex-justify">
            <VehicleActions />
          </div>
        </div>
      </div>

      <div className="grid-row grid-gap-6 margin-top-3 padding-1 tablet:padding-0">
        <div className="tablet:grid-col-3">
          <SideNav
            items={generateSideNavItems({
              items: sideNavItems,
              omitItemsFunction: () => {
                return ['rates', 'tasks', 'activity', 'docs'];
              },
              vehicleVersionId: vehicle?.versionId,
              isCurrent: vehicle?.isCurrent,
            })}
          />
        </div>
        <div className="tablet:grid-col-9">
          {showAlert(tabMsg, setTabMsg)}
          <h2 className="border-bottom-05 border-primary-lighter padding-bottom-1 margin-top-5 tablet:margin-top-0">
            {sideNavItems[activeItem]?.label}
          </h2>
          <AlertsComponent />
          <br />
          {showAlert(sectionMsg, setSectionMsg)}
          {SideNavComponent && <SideNavComponent />}
        </div>
      </div>
    </VehicleContextProvider>
  );
};

VehicleDetails.context = vehicleContext;

export default VehicleDetails;
