import { useLazyQuery } from '@apollo/client';
import { Icon, Menu, Spinner } from '@gsa/afp-component-library';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { emdash } from 'components/common';
import { ExemptPopover } from '../../../utilities/common';
import { GET_VEHICLE_EXPANDED_ROW } from '../../../services/data-layer';
import {
  VehicleRowActionButtonType,
  vehicleTableCellType,
  DetailRowType,
  VehicleDetailsRowType,
} from '../proptypes';

export const VehicleRowActionButton = ({
  vin,
  onClick,
  menuItems,
  vehicle,
}) => {
  return (
    <div className="height-4 width-4">
      <Menu
        onActionClick={(action) => {
          onClick(vin, action, vehicle);
        }}
        menuItems={menuItems}
        iconColor="text-primary"
      />
    </div>
  );
};
VehicleRowActionButton.propTypes = VehicleRowActionButtonType;

export const iconExpanded = (
  <Icon iconName="expand_more" className="usa-icon--size-4" />
);

export const iconCollapsed = (
  <Icon iconName="navigate_next" className="usa-icon--size-4" />
);

export const VehicleTableCell = ({ title, vin, versionId, isCurrent }) => (
  <div>
    <div>
      <strong className="text-uppercase">{title}</strong>
    </div>
    <Link
      className="text-uppercase"
      to={`/vehicles/${encodeURIComponent(vin)}/overview${
        versionId && !isCurrent ? `?version=${versionId}` : ''
      }`}
    >
      {vin}
    </Link>
  </div>
);
VehicleTableCell.propTypes = vehicleTableCellType;

const DetailRow = ({ label, value, className }) => (
  <div className="detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
    <div className="detail-title">
      <strong>{label}</strong>
    </div>
    <div className={`detail-value ${className}`}>{value}</div>
  </div>
);
DetailRow.propTypes = DetailRowType;

export const VehicleDetailsRow = ({ vehicle }) => {
  const [fetchedData, setFetchedData] = useState(null);

  const [getExpandedRowData] = useLazyQuery(GET_VEHICLE_EXPANDED_ROW, {
    fetchPolicy: 'network-only',
    onError: (err) => console.log(err),
    onCompleted: ({ getExpandedVehicleRow: expandedData }) => {
      setFetchedData(expandedData);
    },
  });

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    vehicle.vin && getExpandedRowData({ variables: { vin: vehicle.vin } });
  }, [vehicle.vin]);

  if (!fetchedData) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="display-flex flex-justify-center padding-x-8">
      <div className="vehicle-row-details margin-right-5 flex-1">
        <div className="display-flex flex-align-center">
          <h2 className="text-uppercase font-sans-xs margin-right-105">
            Vehicle Details
          </h2>
        </div>
        <div className="row-details">
          <DetailRow label="Vehicle Color" value={fetchedData.vehicleColor} />
          <DetailRow
            label="State License Plate"
            value={fetchedData.statePlate || emdash}
          />
          {fetchedData.exemptPlate && (
            <div className="detail-row  display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
              <div className="detail-title">
                <strong>License plate</strong>
              </div>
              <div className="detail-value exempt-value-row">
                <div className="exempt-value">Exempt</div>
                <div className="exempt-popover-value-icon  position-absolute">
                  <ExemptPopover />
                </div>
              </div>
            </div>
          )}
          <DetailRow
            label="Requisition #"
            value={fetchedData.requisitionNumber || emdash}
          />
          <DetailRow
            label="Mileage"
            value={fetchedData.mileage ? `${fetchedData.mileage} mi` : emdash}
          />

          <DetailRow label="Registered" value={vehicle.registered} />
        </div>
      </div>
      <div className="vehicle-row-details flex-1">
        <div className="display-flex flex-align-center">
          <h2 className="text-uppercase font-sans-xs margin-right-105">
            Point of Contact
          </h2>
        </div>
        <div className="row-details">
          <DetailRow
            className="text-uppercase"
            label="Name"
            value={fetchedData.pocName}
          />
          <DetailRow
            label="Email"
            value={
              <a aria-label="mailto" href={`mailto:${fetchedData.pocEmail}`}>
                {fetchedData.pocEmail}
              </a>
            }
          />
          <DetailRow label="Phone" value={fetchedData.pocPhone} />
        </div>
      </div>
    </div>
  );
};
VehicleDetailsRow.propTypes = VehicleDetailsRowType;
